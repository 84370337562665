import { Accessor, Show } from "solid-js";
import { A } from "@solidjs/router";
import { useAppState } from "~/AppContext";
import { Avatar } from "./icons";
import { Account } from "~/types/models";
import CasdoorLogin from "./CasdoorLogin";

const getName = (nameRaw?: string) => {
  if (!nameRaw) return "";
  const name = nameRaw.trim();
  return name.substring(0, 10) + (name.length > 10 ? "..." : "");
};

export default function Nav({
  name,
  currentUser,
}: {
  name: string;
  currentUser: Accessor<Account | undefined>;
}) {
  // deferStream: true cause contents order reversed on refresh
  // deferStream: false cause page content gone on mobile devices
  const { t } = useAppState();
  const title = getName(name);

  return (
    <nav class="border-gray-200">
      <div class="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
        <A href={`/blog/${name}`} class="flex items-center justify-center">
          {/* <img src={logo} class="h-8 mr-3" alt="Flowbite Logo" /> */}
          <span class="self-center text-4xl font-semibold whitespace-nowrap dark:text-white">
            {title}
          </span>
        </A>

        <span class="grow" />

        <Show
          when={currentUser()}
          fallback={
            <div class="flex items-center">
              <CasdoorLogin title={t("signin")} />
            </div>
          }
        >
          {(user) => (
            <div class="flex items-center gap-4">
              <A
                href="/dash"
                class="p-0.5 hover:bg-gray-200 rounded-full  dark:hover:bg-gray-800"
              >
                <Show
                  when={user().avatar}
                  fallback={<Avatar class="rounded-full w-10 h-10" />}
                >
                  {(avatar) => (
                    <img
                      src={avatar()}
                      alt="user avatar"
                      class="rounded-full w-10 h-10"
                    />
                  )}
                </Show>
              </A>
            </div>
          )}
        </Show>
      </div>
    </nav>
  );
}
